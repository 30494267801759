import { ClipboardDocumentCheckIcon, ClipboardDocumentListIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';

export const CopyButton = (props: {
  text: string;
}) => {
  const { text } = props;
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = async () => {
    await navigator.clipboard.writeText(text);
    setIsCopied(true);
  };

  return (
    <div className="relative mr-4">
      <input
        className="block w-full pr-14 rounded-md px-3.5 py-2 text-gray-900 disabled:bg-[#f3f3f3] shadow-sm border border-[#d1d5db] placeholder:text-gray-400 focus:border-primary-hover sm:text-sm sm:leading-6"
        disabled
        readOnly
        type="text"
        value={text}
      />
      <button
        className="absolute end-2 top-1/2 -translate-y-1/2 text-gray-500 hover:bg-gray-100 rounded-lg p-2 inline-flex items-center justify-center"
        onClick={handleCopyClick}
        type="button"
      >
        {!isCopied && <ClipboardDocumentListIcon className="h-5 w-5 text-primary" /> }
        {isCopied && <ClipboardDocumentCheckIcon className="h-5 w-5 text-green-600" />}
      </button>
    </div>
  );
};
