import { Menu, Transition } from '@headlessui/react';
import { clsx } from 'clsx';
import { type ReactNode, Fragment } from 'react';

export const Dropdown = (props: {
  menuItems: Array<{
    title: string;
    icon: ReactNode;
    onClick: () => void;
  }>;
  actionIcon: ReactNode;
  className?: string;
}) => {
  const { actionIcon, className, menuItems } = props;

  return (
    <div className={clsx(className, 'w-6 h-6 absolute top-4 right-0')}>
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button as="button">
          {actionIcon}
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className="absolute z-10 right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
          >
            <div className="px-1 py-1">
              {menuItems.map((item) => (
                <Menu.Item key={item.title}>
                  <button
                    className="group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-[#f3f3f3]"
                    onClick={item.onClick}
                    type="button"
                  >
                    {item.icon}
                    {item.title}
                  </button>
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};
