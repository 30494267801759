import { useAppContext } from '@/AppContext';
import { Select } from '@/components/Select';

export const SelectCredentialType = () => {
  const { credentialsTypes, setSelectedCredential } = useAppContext();

  return (
    credentialsTypes ? (
      <Select
        listItems={credentialsTypes}
        onChange={(credentialsType) => setSelectedCredential(credentialsType)}
      />
    ) : null
  );
};
