import { useAuth0 } from '@auth0/auth0-react';
import { LockClosedIcon } from '@heroicons/react/24/solid';

import { Button } from '@/components/Button';

export const LogoutButton = () => {
  const {
    isAuthenticated,
    logout,
  } = useAuth0();

  if (!isAuthenticated) return null;

  return (
    <Button
      onClick={() => {
        logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
        });
      }}
      title="Logout"
      type="button"
    >
      <LockClosedIcon className="h-4 w-4 ml-2" />
    </Button>
  );
};
