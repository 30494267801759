import { useAuth0 } from '@auth0/auth0-react';
import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { getCredentialSets, getCredentialTypes, getFieldsTemplate, getOrganizations } from './services/utils';

export interface IOptions {
  id: number | string;
  name: string;
}

export interface IFields {
  credentialFields: Array<{
    description: string;
    name: string;
    type: number;
    typeOfValue: number;
  }>;
}

export interface ICredential {
  type: number;
  name: string;
  value: string;
}

export interface IOrganizations {
  id: string;
  name: string;
  businessProcessDomainId: string;
}

export interface ICredentialSet {
  credentials: Array<ICredential>;
  friendlyName: string;
  id: string;
  institution: string;
  userId: string;
  organizations: string[];
}

export interface ICredentialSets {
  credentialSets: Array<ICredentialSet>;
}

export interface IMessage {
  open: boolean;
  title: string;
  subtitle: string;
  type: '' | 'danger' | 'info' | 'success' | 'warning';
}

interface IAppContextProps {
  isAuthenticated: boolean;
  openCreateModal: boolean;
  credentialSets: ICredentialSets | undefined;
  token: string;
  fields: IFields | undefined;
  message: IMessage | undefined;
  setMessage: (error: IMessage | undefined) => void;
  organizations: Array<IOrganizations> | undefined;
  selectedOrganizations: Array<IOrganizations> | [];
  setSelectedOrganizations: (organizations: any) => void;
  setOrganizations: (organizations: Array<IOrganizations>) => void;
  setSelectedOrganization: (organizations: IOrganizations | undefined) => void;
  credentialsTypes: Array<IOptions> | undefined;
  setFields: (fields: IFields) => void;
  setToken: (token: string) => void;
  setRefetchId: (id: string) => void;
  setOpenCreateModal: (open: boolean) => void;
  setSelectedInstitution: (institution: IOptions | any) => void;
  setSelectedCredential: (credential: IOptions | any) => void;
  selectedCredential: IOptions | undefined;
  selectedInstitution: IOptions | undefined;
  selectedOrganization: IOrganizations | undefined;
}

const initialState: IAppContextProps = {
  credentialSets: undefined,
  credentialsTypes: undefined,
  fields: undefined,
  isAuthenticated: false,
  message: undefined,
  openCreateModal: false,
  organizations: undefined,
  selectedCredential: undefined,
  selectedInstitution: undefined,
  selectedOrganization: undefined,
  selectedOrganizations: [],
  setFields: () => null,
  setMessage: () => null,
  setOpenCreateModal: () => null,
  setOrganizations: () => null,
  setRefetchId: () => null,
  setSelectedCredential: () => null,
  setSelectedInstitution: () => null,
  setSelectedOrganization: () => null,
  setSelectedOrganizations: () => null,
  setToken: () => null,
  token: '',
};

const AppContext = createContext<IAppContextProps>(initialState);
AppContext.displayName = 'AppContext';

export const AppProvider = ({ children }: { children: ReactNode }) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const [token, setToken] = useState('');
  const [refetchId, setRefetchId] = useState('');
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [fields, setFields] = useState<IFields | undefined>(undefined);
  const [organizations, setOrganizations] = useState<Array<IOrganizations> | undefined>(undefined);
  const [selectedOrganizations, setSelectedOrganizations] = useState<Array<IOrganizations> | []>([]);
  const [selectedInstitution, setSelectedInstitution] = useState<IOptions | undefined>(undefined);
  const [selectedOrganization, setSelectedOrganization] = useState<IOrganizations | undefined>(undefined);
  const [selectedCredential, setSelectedCredential] = useState<IOptions | undefined>(undefined);
  const [credentialSets, setCredentialSets] = useState<ICredentialSets | undefined>(undefined);
  const [credentialsTypes, setCredentialsTypes] = useState<Array<IOptions> | undefined>(undefined);
  const [message, setMessage] = useState<IMessage | undefined>({
    open: false,
    subtitle: '',
    title: '',
    type: '',
  });

  const fetchTokenOnLoad = useCallback(async () => {
    if (isAuthenticated && token === '') {
      const tokenResponse = await getAccessTokenSilently({
        authorizationParams: {
          audience: 'https://credentialmanager.wilqo.com',
          scope: 'manage:credentialsets',
        },
      });
      setToken(tokenResponse);
    }
  }, [getAccessTokenSilently, isAuthenticated, token]);

  const fetchFieldsByCredential = useCallback(async () => {
    if (selectedCredential && selectedInstitution) {
      const fields = await getFieldsTemplate({
        credentialType: selectedCredential.name,
        token,
      });

      const credentials = await getCredentialSets({
        credentialType: selectedCredential.name,
        institution: String(selectedInstitution.id),
        payload: {
          businessProcessDomain: selectedOrganization?.businessProcessDomainId || '',
          organization: selectedOrganization?.id || '',
        },
        token,
      });

      if (fields && credentials) {
        setFields(fields);
        setCredentialSets(credentials);
      }
    }
  }, [selectedCredential, selectedInstitution, selectedOrganization?.businessProcessDomainId, selectedOrganization?.id, token]);

  const fetchCredentialTypes = useCallback(async () => {
    if (selectedInstitution) {
      const credentialTypes = await getCredentialTypes({
        token,
      });
      setCredentialsTypes(credentialTypes.credentialSetTypes);
    }
  }, [selectedInstitution, token]);

  const fetchOrganizations = useCallback(async () => {
    if (selectedInstitution) {
      const response = await getOrganizations({
        institution: String(selectedInstitution.id),
        token,
      });
      setOrganizations(response.organizations);
    }
  }, [selectedInstitution, token]);

  useEffect(() => {
    fetchCredentialTypes();
  }, [fetchCredentialTypes, selectedOrganization]);

  useEffect(() => {
    fetchOrganizations();
  }, [fetchOrganizations, selectedInstitution]);

  useEffect(() => {
    fetchTokenOnLoad();
  }, [fetchTokenOnLoad, getAccessTokenSilently, isAuthenticated, token]);

  useEffect(() => {
    fetchFieldsByCredential();
  }, [fetchFieldsByCredential, selectedInstitution, refetchId]);

  useEffect(() => {
    if (message?.open) {
      setTimeout(() => {
        setMessage({
          open: false,
          subtitle: '',
          title: '',
          type: 'info',
        });
      }, 5000);
    }
  }, [message]);

  return (
    <AppContext.Provider
      value={{
        credentialSets,
        credentialsTypes,
        fields,
        isAuthenticated,
        message,
        openCreateModal,
        organizations,
        selectedCredential,
        selectedInstitution,
        selectedOrganization,
        selectedOrganizations,
        setFields,
        setMessage,
        setOpenCreateModal,
        setOrganizations,
        setRefetchId,
        setSelectedCredential,
        setSelectedInstitution,
        setSelectedOrganization,
        setSelectedOrganizations,
        setToken,
        token,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
