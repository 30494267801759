import { Auth0Provider } from '@auth0/auth0-react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { App } from './App';
import { AppProvider } from './AppContext';
import { getConfig } from './services/config';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') || '/';
const container = document.getElementById('root');
const root = createRoot(container!);
const config = getConfig();

root.render(
  <BrowserRouter basename={baseUrl}>
    <Auth0Provider
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
      clientId={config.auth0ClientId}
      domain={config.auth0Domain}
    >
      <AppProvider>
        <App />
      </AppProvider>
    </Auth0Provider>
  </BrowserRouter>,
);
