import { ShieldCheckIcon } from '@heroicons/react/20/solid';

import { useAppContext } from '@/AppContext';
import { CopyButton } from '@/components/CopyButton';
import { LogoutButton } from '@/features/LogoutButton';

export const NavBar = () => {
  const { token } = useAppContext();

  return (
    <header className="sticky inset-x-0 top-0 z-50 bg-white border-b-4 border-primary mb-12">
      <nav aria-label="Global" className="mx-auto flex max-w-7xl items-center justify-between p-4">
        <div className="flex lg:flex-1">
          <div className="-m-1.5 p-1.5 flex items-center">
            <ShieldCheckIcon aria-hidden="true" className="h-8 w-8 flex-none text-green-700" />
            <span className="ml-2 gradient-logo font-semibold">Credential Manager</span>
          </div>
        </div>
        {
          token && <CopyButton text={token} />
        }
        <LogoutButton />
      </nav>
    </header>
  );
};
