import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Fragment, useState } from 'react';

import type { ICredentialSet } from '@/AppContext';
import { useAppContext } from '@/AppContext';

interface SelectMultipleOrganizationsProps {
  credential?: ICredentialSet;
  credentialIndex?: number;
  getCurrentOrgs?: ({ businessProcessDomainId, newOrganizations }: { businessProcessDomainId: string[]; newOrganizations: string[] }) => void;
  setFilteredData?: (data: any) => void;
}

export const SelectMultipleOrganizations = ({ credential, credentialIndex, getCurrentOrgs, setFilteredData }: SelectMultipleOrganizationsProps) => {
  const { organizations } = useAppContext();
  const [selectedOrganizations, setSelectedOrganizations] = useState<Array<string> | []>(credential?.organizations || []);

  const filteredOrganizations = organizations?.map((org) => ({
    businessProcessDomainId: org.businessProcessDomainId,
    id: org.id.replace('-ORG', ''),
  }));

  const updateOrganizations = (newOrganizations: string[]) => {
    setSelectedOrganizations(newOrganizations.map((org) => org));
    if (getCurrentOrgs && filteredOrganizations) {
      getCurrentOrgs({ businessProcessDomainId: filteredOrganizations.map((org) => org.businessProcessDomainId), newOrganizations });
    }
    if (credential && setFilteredData && credentialIndex !== undefined) {
      setFilteredData((prev: any) => {
        const newData = [...prev];
        newData[credentialIndex].organizations = newOrganizations;
        return newData;
      });
    }
  };

  return (
    <div className="my-6">
      <label className="block text-sm font-semibold leading-6 text-gray-600" htmlFor="selectOrganizations">
        Select Organizations
      </label>
      <Listbox multiple onChange={updateOrganizations} value={selectedOrganizations}>
        <div className="relative mt-1">
          <Listbox.Button
            className="relative w-full cursor-pointer rounded-md bg-white py-2 pl-3 pr-10 text-left text-gray-600 shadow-sm border border-[#d1d5db] placeholder:text-gray-400 focus:border-primary-hover sm:text-sm sm:leading-6"
          >
            <span
              className="block truncate"
            >
              {selectedOrganizations?.length > 0 ? selectedOrganizations.map((org: any) => org).join(', ') : 'Select an Option'}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                aria-hidden="true"
                className="h-5 w-5 text-gray-400"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              className="absolute z-10 -mt-52 max-h-60 w-full overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
            >
              {filteredOrganizations && filteredOrganizations.map((org) => (
                <Listbox.Option
                  key={org.id}
                  className={({ active }) => `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                    active ? 'bg-primary text-white' : 'text-gray-900'
                  }`}
                  value={org.id}
                >
                  {({ active, selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {org.id}
                      </span>
                      {selected ? (
                        <span className={`${active ? 'text-white' : 'text-primary'} absolute inset-y-0 left-0 flex items-center pl-3`}>
                          <CheckIcon aria-hidden="true" className="h-5 w-5" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};
