import { CheckCircleIcon, ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { clsx } from 'clsx';

import { useAppContext } from '@/AppContext';

export const Toaster = () => {
  const { message, setMessage } = useAppContext();

  return (
    message?.open ? (
      <div
        className={clsx('w-full max-w-xs p-4 text-white rounded-lg shadow-md absolute bottom-6 right-6 z-50', {
          'bg--blue-700': message?.type === 'info',
          'bg-green-700': message?.type === 'success',
          'bg-red-700': message?.type === 'danger',
          'bg-yellow-700': message?.type === 'warning',
        })}
        id="toast-interactive"
        role="alert"
      >
        <div className="flex">
          <div className="ms-3 text-sm font-normal">
            <div className="flex items-center justify-start gap-x-3 mb-2">
              {message?.type === 'danger' ? <ExclamationTriangleIcon className="w-7 h-7" /> : <CheckCircleIcon className="w-7 h-7" /> }
              <span className="font-semibold text-lg">
                {message?.title}
              </span>
            </div>
            <div className="mb-2 text-sm font-normal">{message?.subtitle}</div>
          </div>
          <button
            aria-label="Close"
            className="ms-auto -mx-1.5 -my-1.5 bg-white items-center justify-center flex-shrink-0 text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8"
            data-dismiss-target="#toast-interactive"
            onClick={() => setMessage({
              open: false,
              subtitle: '',
              title: '',
              type: 'info',
            })}
            type="button"
          >
            <span className="sr-only">Close</span>
            <XMarkIcon className="w-6 h-6" />
          </button>
        </div>
      </div>
    ) : null
  );
};
