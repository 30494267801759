import './styles.css';

import { Route, Routes } from 'react-router-dom';

import { NavBar } from '@/components/NavBar';
import { Home } from '@/pages/Home';

export const App = () => (
  <div className="bg-slate-50 h-screen">
    <NavBar />
    <div className="container mx-auto grid justify-center items-center gap-9 pb-20">
      <Routes>
        <Route element={<Home />} index />
      </Routes>
    </div>
  </div>
);
