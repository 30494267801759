function getHeaders(token: string) {
  return {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
}

function handleErrors(data: any) {
  return {
    error: true,
    message: data.errors ? Object.keys(data.errors)[0] : 'Something went wrong',
  };
}

async function getCredentialTypes(data: {
  token: string;
}) {
  const { token } = data;

  const response = await fetch(
    'credentialsettypes',
    {
      ...getHeaders(token),
      method: 'GET',
    },
  );

  return response.json();
}

async function getFieldsTemplate(data: {
  credentialType: string;
  token: string;
}) {
  const { credentialType, token } = data;

  const response = await fetch(
    `credentialsets/${credentialType}/fields`,
    {
      ...getHeaders(token),
      method: 'GET',
    },
  );
  return response.json();
}

async function getCredentialSets(data: {
  credentialType: string;
  institution: string;
  payload: {
    'businessProcessDomain': string;
    'organization': string;
  };
  token: string;
}) {
  const { credentialType, institution, token } = data;

  const response = await fetch(
    `credentialsets/v3/available/${institution}/${credentialType}`,
    {
      ...getHeaders(token),
      body: JSON.stringify({
        businessProcessDomain: data.payload.businessProcessDomain,
        organization: data.payload.organization,
      }),
      method: 'POST',
    },
  );

  return response.json();
}

async function getOrganizations(data: {
  institution: string;
  token: string;
}) {
  const { institution, token } = data;

  const response = await fetch(
    `institutions/${institution}`,
    {
      ...getHeaders(token),
      method: 'GET',
    },
  );
  return response.json();
}

async function getInstitutions(data: {
  token: string;
}) {
  const { token } = data;

  const response = await fetch(
    'institutions',
    {
      ...getHeaders(token),
      method: 'GET',
    },
  );
  return response.json();
}

async function deleteCredentialSet(data: {
  credentialId: string;
  token: string;
  institution: string;
}) {
  try {
    const { credentialId, institution, token } = data;

    const response = await fetch(
      `credentialsets/v2/${institution}/${credentialId}`,
      {
        ...getHeaders(token),
        method: 'DELETE',
      },
    );

    if (!response.ok) {
      const data = await response.json();
      handleErrors(data);
    }
    return await response.json();
  } catch (error) {
    return undefined;
  }
}

async function createCredentialSet(data: {
  token: string;
  institution: string;
  fields: {
    'credentials': Array<{
      name: string;
      value: string;
    }>;
    'credentialSetType': number;
    'friendlyName': string;
  };
  organizations: Array<string>;
  businessProcessDomains: Array<string>;
}) {
  try {
    const { businessProcessDomains, fields, institution, organizations, token } = data;
    const { credentialSetType, credentials, friendlyName } = fields;

    const payload = {
      businessProcessDomains,
      credentialSetType,
      credentials,
      friendlyName,
      organizations,
    };

    const response = await fetch(
      `credentialsets/v3/${institution}/create`,
      {
        ...getHeaders(token),
        body: JSON.stringify(payload),
        method: 'PUT',
      },
    );

    if (!response.ok) {
      const errorData = await response.json();

      return { error: true, message: handleErrors(errorData).message };
    }
    const responseData = await response.json();
    return { data: responseData.credentialSetId, message: handleErrors(responseData).message, success: true };
  } catch (error) {
    return { error: true, message: '' };
  }
}

async function editCredentialSet(data: {
  token: string;
  institution: string;
  credentialId: string;
  organizations: Array<string>;
  businessProcessDomains: Array<string>;
  fields: {
    'credentials': Array<{
      name: string;
      value: string;
    }>;
    'friendlyName': string;
  };
}) {
  try {
    const { businessProcessDomains, credentialId, fields, institution, organizations, token } = data;
    const { credentials, friendlyName } = fields;

    const payload = {
      businessProcessDomains,
      credentialId,
      credentials,
      friendlyName,
      organizations,
    };

    const response = await fetch(
      `credentialsets/v3/${institution}/${credentialId}`,
      {
        ...getHeaders(token),
        body: JSON.stringify(payload),
        method: 'POST',
      },
    );

    if (!response.ok) {
      const errorData = await response.json();

      return { error: true, message: handleErrors(errorData).message };
    }
    const responseData = await response.json();
    return { data: responseData, success: true };
  } catch (error) {
    return { error: true, message: '' };
  }
}

export {
  createCredentialSet,
  deleteCredentialSet,
  editCredentialSet,
  getCredentialSets,
  getCredentialTypes,
  getFieldsTemplate,
  getInstitutions,
  getOrganizations,
};
