import { useEffect, useState } from 'react';

import { useAppContext } from '@/AppContext';
import { Select } from '@/components/Select';

import { getInstitutions } from '../services/utils';

interface Institution {
  id: string;
  name: string;
}

export const SelectInstitution = () => {
  const [institutions, setInstitutions] = useState<Institution[]>([]);
  const { setSelectedInstitution, token } = useAppContext();

  useEffect(() => {
    const fetchInstitutions = async () => {
      const result = await getInstitutions({ token });
      if (result?.response) {
        const formattedInstitutions = result.response.map((ins: { displayName: string; id: string }) => ({
          id: ins.id,
          name: ins.displayName,
        }));
        setInstitutions(formattedInstitutions);
      }
    };
    fetchInstitutions();
  }, [token]);

  return (
    <Select
      listItems={institutions}
      onChange={(institution) => setSelectedInstitution(institution)}
    />
  );
};
