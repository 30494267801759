import { ArrowPathIcon } from '@heroicons/react/20/solid';
import { clsx } from 'clsx';
import type { ReactNode } from 'react';

export const Button = (props: {
  title: string;
  type?: 'button' | 'reset' | 'submit';
  children?: ReactNode;
  onClick?: () => void;
  className?: string;
  loading?: boolean;
}) => {
  const { children, className = '', loading = false, onClick, title, type = 'button' } = props;

  return (
    <button
      className={
        clsx(className, 'w-32 flex items-center justify-center rounded-md bg-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-hover')
      }
      onClick={onClick}
      type={type}
    >
      {loading && (
        <>
          <ArrowPathIcon className="animate-spin h-5 w-5 text-white mr-2" />
          <span>Processing...</span>
        </>
      )}
      {
        !loading && (
          <>
            {title}
            {children}
          </>
        )
      }
    </button>
  );
};
