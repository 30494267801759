import { useEffect, useState } from 'react';

import { useAppContext } from '@/AppContext';
import { Select } from '@/components/Select';

export const SelectOrganization = () => {
  const { organizations, selectedInstitution, setSelectedCredential, setSelectedOrganization } = useAppContext();
  const [resetValues, setResetValues] = useState(false);

  useEffect(() => {
    setResetValues(true);
    setSelectedOrganization(undefined);
    setSelectedCredential(undefined);
  }, [selectedInstitution]);

  return (
    organizations ? (
      <Select
        allowEmpty
        listItems={organizations}
        onChange={(organization) => setSelectedOrganization(organization)}
        resetValues={resetValues}
        setResetValues={setResetValues}
      />
    ) : null
  );
};
