import { PlusCircleIcon } from '@heroicons/react/20/solid';

import { useAppContext } from '@/AppContext';
import { Card } from '@/components/Card';
import { Toaster } from '@/components/Toaster';
import { EditCredentials } from '@/features/EditCredentials';
import { LoginButton } from '@/features/LoginButton';
import { SelectCredentialType } from '@/features/SelectCredentialType';
import { SelectInstitution } from '@/features/SelectInstitution';
import { SelectOrganization } from '@/features/SelectOrganization';

export const Home = () => {
  const {
    credentialSets,
    credentialsTypes,
    fields,
    isAuthenticated,
    organizations,
    selectedCredential,
    selectedInstitution,
    selectedOrganization,
    setOpenCreateModal,
  } = useAppContext();

  return (
    <>
      {!isAuthenticated && (
        <Card isLoading={false} subtitle="Please Login to start" title="Credential Manager">
          <LoginButton />
        </Card>
      )}
      {
        isAuthenticated && (
          <div className="flex flex-wrap w-full justify-center items-stretch gap-8">
            <div className="block">

              <Card className="mb-4" isLoading={false} subtitle="Please select an institution" title="Institutions">
                <SelectInstitution />
              </Card>

              {
                (selectedInstitution && organizations) && (
                  <Card
                    className="mb-4"
                    isLoading={false}
                    subtitle="Please select an Organization"
                    title="Organizations"
                  >
                    <SelectOrganization />
                  </Card>
                )
              }
              {
                selectedOrganization && (
                  <Card
                    className="mb-4"
                    isLoading={!credentialsTypes}
                    subtitle="Please select a credential type"
                    title="Credentials Type"
                  >
                    <SelectCredentialType />
                  </Card>
                )
              }
            </div>
            {
              selectedCredential && (
                <Card
                  iconTrigger={(
                    <PlusCircleIcon
                      aria-hidden="true"
                      className="h-9 w-9 text-primary absolute top-3 right-6 hover:cursor-pointer"
                      onClick={() => {
                        setOpenCreateModal(true);
                      }}
                    />
                  )}
                  isLoading={!credentialSets && !fields}
                  subtitle={selectedCredential?.name}
                  title="Update Credentials"
                >
                  <EditCredentials />
                </Card>
              )
            }
          </div>
        )
      }
      <Toaster />
    </>
  );
};
