import { Disclosure, Transition } from '@headlessui/react';
import { ChevronUpIcon, EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { clsx } from 'clsx';
import type { ReactNode } from 'react';

import { Dropdown } from './Dropdown';

export const Accordion = (props: {
  children: ReactNode;
  title: string;
  iconTrigger?: ReactNode;
  showMenu?: boolean;
  menuItems?: Array<{
    title: string;
    icon: ReactNode;
    onClick: () => void;
  }>;
}) => {
  const { children, iconTrigger = null, menuItems = [], showMenu = false, title } = props;

  return (
    <div>
      <Disclosure>
        {({ open }) => (
          <>
            <div className="relative">
              <Disclosure.Button
                as="button"
                className={clsx('flex w-full justify-between p-4 text-left text-sm font-medium bg-white shadow-sm border border-[#d1d5db]', {
                  'rounded-md': !open,
                  'rounded-t-md': open,
                })}
              >
                <span>{title}</span>
                <ChevronUpIcon
                  className={clsx('h-5 w-5', {
                    '': !open,
                    'rotate-180 transform': open,
                  })}
                />
              </Disclosure.Button>
              {iconTrigger}
              {
                showMenu && (
                  <Dropdown
                    actionIcon={(
                      <EllipsisVerticalIcon
                        aria-hidden="true"
                        className=" h-5 w-5"
                      />
                    )}
                    menuItems={menuItems}
                  />
                )
              }
            </div>
            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel className="p-4 mb-7 rounded-b-lg shadow-sm border-x border-[#d1d5db] bg-white">
                {children}
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  );
};
