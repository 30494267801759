import { SparklesIcon } from '@heroicons/react/20/solid';

import { Button } from '@/components/Button';

export const EmptyState = (props: {
  title: string;
  subtitle: string;
  onClick: () => void;
  btnLabel: string;
}) => {
  const { btnLabel = '', onClick, subtitle, title } = props;

  return (
    <div
      className="container mx-auto bg-white rounded-md"
    >
      <div
        className="flex flex-wrap justify-center items-center text-center border-dashed border-2 border-slate-200 rounded-md py-20 px-16 w-full"
      >
        <SparklesIcon className="h-8 w-full text-primary mb-4" />
        <p className="text-xl mb-2 uppercase font-bold">{title}</p>
        <span className="text-m text-slate-400 block mb-10">
          {subtitle}
        </span>
        <Button className="w-auto" onClick={onClick} title={btnLabel} />
      </div>
    </div>
  );
};
