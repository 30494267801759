import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Fragment, useEffect, useState } from 'react';

export const Select = (props: {
  listItems: Array<any>;
  onChange: (data: any) => void;
  setResetValues?: any;
  resetValues?: boolean;
  allowEmpty?: boolean;
}) => {
  const { allowEmpty = false, listItems, onChange, resetValues, setResetValues } = props;

  const emptyOption = {
    id: '',
    name: 'None',
  };

  const [selected, setSelected] = useState({
    id: 'none',
    name: 'Select an Option',
  });

  useEffect(() => {
    if (resetValues && setResetValues) {
      setSelected(emptyOption);
      setResetValues(false);
    }
    // eslint-disable-next-line
  }, [resetValues,setResetValues]);

  const handleOnChange = (data: any) => {
    setSelected(data);
    if (setResetValues) {
      setResetValues(false);
    }
    onChange(data);
  };

  return (
    <Listbox onChange={handleOnChange} value={selected}>
      <div className="relative mt-1">
        <Listbox.Button
          className="relative w-full cursor-pointer rounded-md bg-white py-2 pl-3 pr-10 text-left text-gray-600 shadow-sm border border-[#d1d5db] placeholder:text-gray-400 focus:border-primary-hover sm:text-sm sm:leading-6"
        >
          <span className="block truncate">{selected.name}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              aria-hidden="true"
              className="h-5 w-5 text-gray-400"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options
            className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
          >

            {
              allowEmpty && (
                <Listbox.Option
                  className={({ active }) => `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                    active ? 'bg-primary text-white' : 'text-gray-900'
                  }`}
                  value={emptyOption}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {emptyOption.name}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary">
                          <CheckIcon aria-hidden="true" className="h-5 w-5" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              )
            }

            {listItems.map((list) => (
              <Listbox.Option
                key={list.id}
                className={({ active }) => `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                  active ? 'bg-primary text-white' : 'text-gray-900'
                }`}
                value={list}
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={`block truncate ${
                        selected ? 'font-medium' : 'font-normal'
                      }`}
                    >
                      {list.name}
                    </span>
                    {selected ? (
                      <span className={`${active ? 'text-white' : 'text-primary'} absolute inset-y-0 left-0 flex items-center pl-3`}>
                        <CheckIcon aria-hidden="true" className="h-5 w-5" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};
