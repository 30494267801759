import type { ChangeEvent, SyntheticEvent } from 'react';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useAppContext } from '@/AppContext';
import { Button } from '@/components/Button';
import { SelectMultipleOrganizations } from '@/features/SelectMultipleOrganizations';
import { createCredentialSet } from '@/services/utils';

export const CreateCredentials = () => {
  const {
    fields,
    selectedCredential,
    selectedInstitution,
    setMessage,
    setOpenCreateModal,
    setRefetchId,
    token,
  } = useAppContext();
  const [form, setForm] = useState<any>({});
  const [currentOrgs, setCurrentOrgs] = useState<{ businessProcessDomainId: string[]; newOrganizations: string[] }>({ businessProcessDomainId: [], newOrganizations: [] });
  if (!fields) return null;

  const credentialFields = fields.credentialFields.map(({
    type,
    typeOfValue,
    ...rest
  }) => rest);

  const getCurrentOrgs = ({ businessProcessDomainId, newOrganizations }: any) => {
    setCurrentOrgs({ businessProcessDomainId, newOrganizations });
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    if (selectedInstitution && selectedCredential) {
      const keys = Object.keys(form).filter((key) => key !== 'friendlyName');

      const credentials = keys.map((key) => ({
        name: key,
        value: form[key],
      }));

      const businessProcessDomains = [...new Set(currentOrgs.businessProcessDomainId)];
      const organizations = currentOrgs.newOrganizations;

      const response = await createCredentialSet({
        businessProcessDomains,
        fields: {
          credentialSetType: Number(selectedCredential.id),
          credentials,
          friendlyName: form.friendlyName,
        },
        institution: String(selectedInstitution.id),
        organizations,
        token,
      });

      if (response.message) {
        setMessage({
          open: true,
          subtitle: response.message,
          title: 'Warning',
          type: 'warning',
        });
        setOpenCreateModal(false);
        setRefetchId(uuidv4());
      } else if (response.error) {
        setMessage({
          open: true,
          subtitle: response.message,
          title: 'Error',
          type: 'danger',
        });
      } else {
        setOpenCreateModal(false);
        setRefetchId(uuidv4());
      }
    }
  };

  return (
    <form className="mx-auto" onSubmit={handleSubmit}>
      <div className="mb-4">
        <label className="block text-sm font-semibold leading-6 text-gray-600" htmlFor="friendlyName">
          Title
        </label>
        <div className="mt-2.5">
          <input
            className="block w-full rounded-md px-3.5 py-2 text-gray-600 shadow-sm border border-[#d1d5db] placeholder:text-gray-400 focus:border-primary-hover sm:text-sm sm:leading-6"
            id="friendlyName"
            name="friendlyName"
            onChange={handleChange}
            type="text"
          />
        </div>
      </div>
      <div className="grid gap-x-8 gap-y-6 grid-cols-2">
        {
            credentialFields.map((field) => (
              <div key={field.name}>
                <label className="block text-sm font-semibold leading-6 text-gray-600" htmlFor={field.name}>
                  {field.description}
                </label>
                <div className="mt-2.5">
                  <input
                    className="block w-full rounded-md px-3.5 py-2 text-gray-600 shadow-sm border border-[#d1d5db] placeholder:text-gray-400 focus:border-primary-hover sm:text-sm sm:leading-6"
                    id={field.name}
                    name={field.name}
                    onChange={handleChange}
                    type="text"
                  />
                </div>
              </div>
            ))
          }
      </div>
      <SelectMultipleOrganizations getCurrentOrgs={getCurrentOrgs} />
      <div className="mt-4 flex justify-end items-center">
        <Button title="Create" type="submit" />
      </div>
    </form>
  );
};
