import { useAuth0 } from '@auth0/auth0-react';
import { ArrowSmallRightIcon } from '@heroicons/react/24/outline';

import { Button } from '@/components/Button';

export const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <div className="w-full flex items-center justify-center">
      <Button
        onClick={() => loginWithRedirect({
          authorizationParams: {
            audience: 'https://credentialmanager.wilqo.com',
            scope: 'manage:credentialsets',
          },
        })}
        title="Log In"
        type="button"
      >
        <ArrowSmallRightIcon className="h-4 w-4 ml-2" />
      </Button>
    </div>
  );
};
