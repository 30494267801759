import { CircleStackIcon, TrashIcon } from '@heroicons/react/20/solid';
import type { ChangeEvent, SyntheticEvent } from 'react';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import type { ICredentialSet } from '@/AppContext';
import { useAppContext } from '@/AppContext';
import { Accordion } from '@/components/Accordion';
import { Button } from '@/components/Button';
import { EmptyState } from '@/components/EmptyState';
import { Modal } from '@/components/Modal';
import { SelectMultipleOrganizations } from '@/features/SelectMultipleOrganizations';
import { deleteCredentialSet, editCredentialSet } from '@/services/utils';
import { getAvailableFields } from '@/utils/helpers';

import { CreateCredentials } from './CreateCredentials';

export const EditCredentials = () => {
  const {
    credentialSets,
    fields,
    openCreateModal,
    selectedCredential,
    selectedInstitution,
    selectedOrganizations,
    setMessage,
    setOpenCreateModal,
    setRefetchId,
    token,
  } = useAppContext();
  const [form, setForm] = useState<any>({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [credentialId, setCredentialId] = useState('');
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [filteredData, setFilteredData] = useState<ICredentialSet[]>([]);

  useEffect(() => {
    setFilteredData(getAvailableFields(fields, credentialSets));
  }, [fields, credentialSets]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event: SyntheticEvent, credential: ICredentialSet) => {
    event.preventDefault();

    if (selectedInstitution && selectedCredential) {
      const keys = Object.keys(form).filter((key) => key !== 'friendlyName');

      const credentials = keys.map((key) => ({
        name: key,
        value: form[key],
      }));

      const businessProcessDomains = selectedOrganizations.map((organization) => organization.businessProcessDomainId);
      const { organizations } = credential;

      const response = await editCredentialSet({
        businessProcessDomains,
        credentialId: credential.id,
        fields: {
          credentials,
          friendlyName: form.friendlyName ? form.friendlyName : credential.friendlyName,
        },
        institution: String(selectedInstitution.id),
        organizations,
        token,
      });

      if (response.error) {
        setMessage({
          open: true,
          subtitle: response.message,
          title: 'Error',
          type: 'danger',
        });
      } else {
        setMessage({
          open: true,
          subtitle: 'Credential updated successfully',
          title: 'success',
          type: 'success',
        });
        setOpenCreateModal(false);
        setRefetchId(uuidv4());
      }
    }
  };

  const handleDelete = async (credentialId: string) => {
    if (selectedInstitution && credentialId) {
      setLoadingDelete(true);
      const response = await deleteCredentialSet({
        credentialId,
        institution: String(selectedInstitution.id),
        token,
      });
      if (response.error) {
        setMessage({
          open: true,
          subtitle: response.message,
          title: 'Error',
          type: 'danger',
        });
      } else {
        setMessage({
          open: true,
          subtitle: 'Credential deleted successfully',
          title: 'success',
          type: 'success',
        });
        setOpenDeleteModal(false);
        setRefetchId(uuidv4());
        setLoadingDelete(false);
      }
    }
  };

  return (
    <>
      {(!filteredData || filteredData.length === 0)
        && (
          <EmptyState
            btnLabel="Create credentials"
            onClick={() => setOpenCreateModal(true)}
            subtitle={`Create credentials for ${selectedInstitution?.name}`}
            title="No Credentials found"
          />
        )}
      {filteredData && filteredData.length > 0 && (
        <div className="grid gap-y-4">
          {
            filteredData.map((credential, index) => (
              <Accordion
                key={credential.id}
                title={credential.friendlyName}
              >
                <form className="mx-auto" onSubmit={(e) => handleSubmit(e, credential)}>
                  <div className="mb-4">
                    <label className="block text-sm font-semibold leading-6 text-gray-600" htmlFor="friendlyName">
                      Title
                    </label>
                    <div className="mt-2.5">
                      <input
                        className="block w-full rounded-md px-3.5 py-2 text-gray-600 shadow-sm border border-[#d1d5db] placeholder:text-gray-400 focus:border-primary-hover sm:text-sm sm:leading-6"
                        defaultValue={credential.friendlyName || ''}
                        id="friendlyName"
                        name="friendlyName"
                        onChange={handleChange}
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                    {
                      credential?.credentials.map((field) => (
                        <div key={field.name}>
                          <label className="block text-sm font-semibold leading-6 text-gray-600" htmlFor={field.name}>
                            {field.name}
                          </label>
                          <div className="mt-2.5">
                            <input
                              className="block w-full rounded-md px-3.5 py-2 text-gray-900 shadow-sm border border-[#d1d5db] placeholder:text-gray-400 focus:border-primary-hover sm:text-sm sm:leading-6"
                              defaultValue={field.value || ''}
                              id={field.name}
                              name={field.name}
                              onChange={handleChange}
                              type="text"
                            />
                          </div>
                        </div>
                      ))
                    }
                  </div>
                  <SelectMultipleOrganizations
                    credential={credential}
                    credentialIndex={index}
                    setFilteredData={setFilteredData}
                  />
                  <div className="mt-10 flex justify-between items-center">
                    <Button
                      className="bg-red-700 hover:bg-red-900"
                      onClick={() => {
                        setCredentialId(credential.id);
                        setOpenDeleteModal(true);
                      }}
                      title="Delete"
                      type="button"
                    >
                      <TrashIcon
                        aria-hidden="true"
                        className="h-4 w-4 ml-2 text-white"
                      />
                    </Button>
                    <Button
                      loading={false}
                      title="Save"
                      type="submit"
                    >
                      <CircleStackIcon
                        aria-hidden="true"
                        className="h-4 w-4 ml-2 text-white"
                      />
                    </Button>
                  </div>
                </form>
              </Accordion>
            ))
          }
        </div>
      )}

      <Modal
        btnLabel="Delete"
        closeModalOnClick={() => setOpenDeleteModal(false)}
        confirmationOnClick={() => handleDelete(credentialId)}
        isOpen={openDeleteModal}
        loading={loadingDelete}
        subtitle="Are you sure you want to delete this credential"
        title="Delete Credentials"
      />
      <Modal
        closeModalOnClick={() => setOpenCreateModal(false)}
        hideButton
        isOpen={openCreateModal}
        title="Create Credentials"
      >
        <CreateCredentials />
      </Modal>
    </>
  );
};
