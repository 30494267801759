import type { ICredentialSets, IFields } from '@/AppContext';

function getAvailableFields(fields: IFields | undefined, credentialSets: ICredentialSets | undefined) {
  if (!fields || !credentialSets) return [];
  const availableFieldKeys = fields.credentialFields.map(({
    type,
    typeOfValue,
    ...rest
  }) => rest).map((prop) => prop.name) || [];

  return credentialSets.credentialSets.map((data) => ({
    ...data,
    credentials: data.credentials.filter((credential) => availableFieldKeys.includes(credential.name)),
  }));
}

export {
  getAvailableFields,
};
