import { PlusCircleIcon } from '@heroicons/react/20/solid';
import { clsx } from 'clsx';
import type { ReactNode } from 'react';

import { Dropdown } from '@/components/Dropdown';

export const Card = (props: {
  children: ReactNode;
  isLoading: boolean;
  title: string;
  subtitle?: string;
  showMenu?: boolean;
  iconTrigger?: ReactNode;
  className?: string;
  menuItems?: Array<{
    title: string;
    icon: ReactNode;
    onClick: () => void;
  }>;
}) => {
  const { children, className, iconTrigger = null, isLoading, menuItems, showMenu = false, subtitle = '', title } = props;

  return (
    <div
      className={clsx(className, 'bg-[#f9f9f9] min-w-[450px] max-w-[450px] block relative p-6 border border-gray-200 rounded-lg shadow')}
    >
      {isLoading && (
        <div className="animate-pulse flex space-x-4">
          <div className="flex-1 space-y-6 py-1">
            <div className="h-7 bg-slate-200 rounded" />
            <div className="h-7 bg-slate-200 rounded" />
            <div className="h-6 bg-slate-200 rounded" />
            <div className="flex justify-center">
              <div className="h-6 bg-slate-200 rounded w-1/2" />
            </div>
          </div>
        </div>
      )}
      {
        !isLoading && (
          <>
            {showMenu && menuItems && (
              <Dropdown
                actionIcon={(
                  <PlusCircleIcon
                    aria-hidden="true"
                    className=" h-9 w-9 text-primary"
                  />
                )}
                className="right-6"
                menuItems={menuItems}
              />
            )}
            {iconTrigger}
            <div>
              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 text-center">
                {title}
              </h5>
              {
                subtitle !== '' && (
                  <p className="mb-3 font-normal text-gray-700 text-center">
                    {subtitle}
                  </p>
                )
              }
            </div>
            {children}
          </>
        )
      }
    </div>
  );
};
